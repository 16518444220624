import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { styleMixins } from '@grafana/ui';

export function getStyles(theme: GrafanaTheme2) {
  return {
    search: css({
      marginTop: theme.spacing(1),
      width: '100%',

      '&:focus-within': {
        ...styleMixins.getFocusStyles(theme),
      },

      '& input': {
        '&:focus-visible': {
          boxShadow: 'none',
        },
      },

      '& button': {
        height: '100%',
        width: '100%',
        borderTop: `1px solid ${theme.colors.border.medium}`,
        borderBottom: `1px solid ${theme.colors.border.medium}`,
        borderRight: `1px solid ${theme.colors.border.medium}`,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        background: theme.colors.background.canvas,
        marginRight: 0,
      },

      '&:hover': {
        '& button': {
          borderTop: `1px solid ${theme.colors.border.strong}`,
          borderBottom: `1px solid ${theme.colors.border.strong}`,
          borderRight: `1px solid ${theme.colors.border.strong}`,
        },
      },
    }),
  };
}

import { isEqual } from 'lodash';
import { Unsubscribable } from 'rxjs';

import { ConfigOverrideRule, FieldColorModeId, FieldConfigProperty, FieldMatcherID } from '@grafana/data';
import { GroupByVariable, sceneGraph, SceneQueryRunner, VizPanel } from '@grafana/scenes';
import { GraphGradientMode } from '@grafana/schema';

import { SCENES_APP_GROUP_BY_VAR } from 'components/common/variables';

function makeOverrides(refId: string, baselineColor: boolean | undefined): ConfigOverrideRule[] {
  if (!baselineColor) {
    return [];
  }

  return [
    {
      matcher: {
        id: FieldMatcherID.byFrameRefID,
        options: `${refId}`,
      },
      properties: [
        {
          id: FieldConfigProperty.Color,
          value: {
            mode: FieldColorModeId.PaletteClassic,
          },
        },
        {
          id: 'custom.fillOpacity',
          value: 0,
        },
        {
          id: 'custom.gradientMode',
          value: GraphGradientMode.None,
        },

        {
          id: 'custom.hideFrom',
          value: {
            legend: false,
            tooltip: false,
            viz: false,
          },
        },

        {
          id: 'displayName',
          value: '${__field.labels.schema} ${__field.labels.instance}',
        },
      ],
    },
  ];
}

function updateQueriesWithGroupBy(
  queryRunner: SceneQueryRunner,
  groupByVariable: GroupByVariable,
  query: (groupBy: string[] | undefined) => string,
  refId: string
) {
  const currentGroupBy = groupByVariable.state.value;

  const groupBy =
    Array.isArray(currentGroupBy) && currentGroupBy.length > 0 ? [`$${SCENES_APP_GROUP_BY_VAR}`] : undefined;

  const currentQueries = queryRunner.state.queries || [];
  const newQueries = [
    {
      refId,
      expr: query(groupBy),
    },
  ];

  if (!isEqual(currentQueries, newQueries)) {
    queryRunner.setState({ queries: newQueries });
    queryRunner.runQueries();
  }
}

export function getConfigurePanelBehavior(
  queryRunner: SceneQueryRunner,
  query: (groupBy: string[] | undefined) => string,
  refId: string
) {
  return (panel: VizPanel) => {
    const groupByVariable = sceneGraph.lookupVariable(SCENES_APP_GROUP_BY_VAR, panel) as GroupByVariable;

    const updateFieldConfig = () => {
      const groupByValue = groupByVariable.state.value;
      const applySolidColors = Array.isArray(groupByValue) && groupByValue.length > 0;

      const overrides = makeOverrides(refId, applySolidColors ? true : undefined);

      panel.setState({
        fieldConfig: {
          ...panel.state.fieldConfig,
          overrides,
        },
      });
    };

    // Initial config update
    updateQueriesWithGroupBy(queryRunner, groupByVariable, query, refId);
    updateFieldConfig();

    const unsubscribables: Unsubscribable[] = [];

    // Subscribe to groupBy value changes
    unsubscribables.push(
      groupByVariable.subscribeToState((newState, oldState) => {
        if (!isEqual(newState.value, oldState.value)) {
          updateQueriesWithGroupBy(queryRunner, groupByVariable, query, refId);
          updateFieldConfig();
        }
      })
    );

    // Cleanup subscriptions when panel unmounts
    return () => {
      unsubscribables.forEach((u) => u.unsubscribe());
    };
  };
}

import { promql, Expression, MatchingOperator } from 'tsqtsq';

import { SceneQueryRunner, VizPanel, VizPanelExploreButton } from '@grafana/scenes';
import { FieldColorModeId, GraphDrawStyle, GraphGradientMode, LineInterpolation } from '@grafana/schema';
import { PointVisibility } from '@grafana/ui';

import { JOB_NAME, PROMETHEUS_DS } from 'components/common/variables';
import { DEFAULT_FILL_OPACITY } from 'constants/panels';
import { transformQuery } from 'pages/transformQuery';
import { RATE_COLOR } from 'utils/colors';

import { getConfigurePanelBehavior } from './configurePanelBehavior';

function getQuery(groupBy: string[] | undefined) {
  const defaultValues: Record<string, string> = { job: JOB_NAME };

  const statementsTotal = new Expression({
    metric: 'mysql_perf_schema_events_statements_total',
    values: defaultValues,
    defaultOperator: MatchingOperator.regexMatch,
  }).toString();

  return promql.sum({
    by: groupBy,
    expr: promql.rate({
      expr: statementsTotal,
      interval: '$__rate_interval:',
    }),
  });
}

export function ratePanel() {
  const queryRunner = new SceneQueryRunner({
    datasource: PROMETHEUS_DS,
    queries: [],
  });

  const configurePanelBehavior = getConfigurePanelBehavior(queryRunner, getQuery, 'OverviewRatePanel');

  return new VizPanel({
    title: 'Rate',
    pluginId: 'timeseries',
    $data: queryRunner,
    headerActions: [new VizPanelExploreButton({ transform: transformQuery })],

    options: { tooltip: { mode: 'multi' } },

    fieldConfig: {
      defaults: {
        min: 0,
        unit: 'req/s',
        color: {
          mode: FieldColorModeId.Fixed,
          fixedColor: RATE_COLOR,
        },
        custom: {
          fillOpacity: DEFAULT_FILL_OPACITY,
          spanNulls: true,
          drawStyle: GraphDrawStyle.Line,
          gradientMode: GraphGradientMode.Opacity,
          lineInterpolation: LineInterpolation.Smooth,
          showPoints: PointVisibility.Always,

          hideFrom: {
            legend: true,
            tooltip: false,
            viz: false,
          },
        },
        displayName: 'Rate',
      },
      overrides: [],
    },
    $behaviors: [configurePanelBehavior],
  });
}

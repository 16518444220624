import { SceneDataTransformer, SceneQueryRunner } from '@grafana/scenes';

import { JOB_NAME, VAR_LOKI_DATASOURCE } from 'components/common/variables';

import { QueryDetailsStat } from './QueryDetailsStat';

function getData(digest: string, schema: string) {
  const queryRunner = new SceneQueryRunner({
    datasource: {
      uid: VAR_LOKI_DATASOURCE,
      type: 'loki',
    },
    queries: [
      {
        refId: 'A',
        // this is logQL
        expr: `sum by(table) (count_over_time({job="${JOB_NAME}"} | logfmt | digest = \`${digest}\` | schema = \`${schema}\` | op = \`query_parsed_table_name\` | line_format \`{{.table}}\` [15m]))`,
        instant: true,
      },
    ],
  });

  return new SceneDataTransformer({
    $data: queryRunner,
    transformations: [
      {
        id: 'organize',
        options: {
          excludeByName: {},
          includeByName: {
            table: true,
          },
          indexByName: {},
          renameByName: {},
        },
      },
    ],
  });
}

export function tableStat(digest: string, schema: string) {
  return new QueryDetailsStat({
    title: 'Tables',
    $data: getData(digest, schema),
  });
}

import { binary_to_base58, base58_to_binary } from 'base58-js';

export const encodeInstance = (decodedInstance: string): string => {
  const encoder = new TextEncoder();
  return binary_to_base58(encoder.encode(decodedInstance));
};

export const decodeInstance = (encodedInstance: string): string => {
  const decoder = new TextDecoder();
  return decoder.decode(base58_to_binary(encodedInstance));
};

// Based on RFC 3986 allows square brackets for IPv6 but replaces all other characters
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURI#encoding_for_rfc3986
export const encodeRFC3986URI = (str: string): string => {
  return encodeURI(str)
    .replace(/%5B/g, '[')
    .replace(/%5D/g, ']')
    .replace(/[!'()*]/g, (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`);
};

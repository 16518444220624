import { cx } from '@emotion/css';
import React, { useEffect, useMemo } from 'react';

import { DataFrameView, GrafanaTheme2, LoadingState, PanelData } from '@grafana/data';
import { SceneComponentProps, sceneGraph, SceneObjectBase } from '@grafana/scenes';
import { Box, InteractiveTable, LoadingBar, Text, useStyles2 } from '@grafana/ui';

import { getStyles } from 'components/interactiveTable/InteractiveTableViz.styles';
import { getCustomColumnStyles } from 'components/interactiveTable/getColumnStyles';
import { InteractiveTableVizState, SortDirection, TableStateInfoProps } from 'components/interactiveTable/types';
import { useDebouncedSearch } from 'components/interactiveTable/useDebouncedSearch';
import messageFromError from 'utils/utils.api';

import { PanelStatus } from './PanelStatus';

export class InteractiveTableViz extends SceneObjectBase<InteractiveTableVizState> {
  static Component = ({ model }: SceneComponentProps<InteractiveTableViz>) => {
    const { data } = sceneGraph.getData(model).useState();
    const {
      columns,
      getRowId,
      initialSortBy,
      expandedRowRenderer,
      pageSize,
      title,
      noDataMessage = 'No data',
      loadingMessage,
      hideBorder,
    } = model.useState();

    const styles = useStyles2((t: GrafanaTheme2) => getStyles(t));

    useEffect(() => {
      if (initialSortBy && initialSortBy.length > 0) {
        model.setState({
          sortDirection: initialSortBy[0].desc ? SortDirection.Descending : SortDirection.Ascending,
          sortedColumnId: initialSortBy[0].id,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tableDataMemoized = useMemo(() => {
      if (!data || data.series?.length === 0) {
        return [];
      }

      const frame = data.series[0];
      const view = new DataFrameView(frame);
      let rows;

      try {
        rows = view.toArray();
      } catch (e) {
        return [];
      }

      return rows;
    }, [data]);

    const { filteredTableData } = useDebouncedSearch(tableDataMemoized);
    //#endregion

    const columnsMemoized = useMemo(() => {
      let cols = [...columns];

      // Remove empty columns
      if (data && data.series.length) {
        const fieldNames = data?.series[0].fields
          .map((field) => {
            const hasItems = field?.values?.some((v) => v != null);
            return hasItems ? field.name : undefined;
          })
          .filter(Boolean);

        for (const column of [...columns]) {
          if (!fieldNames.includes(column.id) && !column.keepEmpty) {
            cols = cols.filter(({ id }) => id !== column.id);
          }
        }
      }

      return cols;
    }, [columns, data]);

    const headerTooltips = useMemo(() => {
      const toolips: Record<string, { content: string }> = {};
      for (const col of columns) {
        if (col.tooltip) {
          toolips[col.id] = col.tooltip;
        }
      }
      return toolips;
    }, [columns]);

    const loading = isLoadingOrStreaming(data);

    return (
      <div className={cx(styles.container, [{ [styles.hideBorder]: hideBorder }])}>
        {loading && <LoadingBar width={900} ariaLabel={`loading ${title}`} />}
        <div className={styles.tableContentWrapper} data-testid="Interactive table">
          <div className={styles.tableControls}>
            {data?.errors && (
              <PanelStatus message={messageFromError(data?.errors)} onClick={() => {}} ariaLabel="Panel status" />
            )}
            {title && (
              <Box element="h6" marginLeft={1} marginBottom={0}>
                {title}
              </Box>
            )}
          </div>

          {/* For more possible options see InteractiveTable doc: https://github.com/grafana/grafana/blob/da07ca9818265da6fa9f2fa2fbb3edae6d9b2174/packages/grafana-ui/src/components/InteractiveTable/InteractiveTable.mdx#L1 */}
          {filteredTableData.length > 0 ? (
            <InteractiveTable
              pageSize={pageSize}
              className={cx(styles.table, getCustomColumnStyles(columnsMemoized, Boolean(expandedRowRenderer)))}
              columns={columnsMemoized.map((col) => {
                const { width, ...originalCol } = col;
                return originalCol as any;
              })}
              headerTooltips={headerTooltips}
              getRowId={getRowId}
              data={filteredTableData}
              renderExpandedRow={
                expandedRowRenderer
                  ? (row) => <div className={styles.tableExpandedRowContainer}>{expandedRowRenderer(row)}</div>
                  : undefined
              }
              initialSortBy={initialSortBy}
            />
          ) : (
            <TableStateInfo
              data={data}
              loadingMessage={loadingMessage}
              noDataMessage={noDataMessage}
              tableDataMemoized={filteredTableData}
            />
          )}
        </div>
      </div>
    );
  };
}

function isLoadingOrStreaming(data: PanelData | undefined) {
  return data?.state === LoadingState.Loading || data?.state === LoadingState.Streaming;
}

function TableStateInfo({ data, tableDataMemoized = [], noDataMessage, loadingMessage }: TableStateInfoProps) {
  const styles = useStyles2((t: GrafanaTheme2) => getStyles(t));

  const state = data?.state;
  const tableDataEmpty = !tableDataMemoized.length;

  let tableStateInfo = null;

  if (state === LoadingState.Loading) {
    tableStateInfo = (
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
        {loadingMessage && <div className={styles.loadingMessage}>{loadingMessage}</div>}
      </Box>
    );
  }

  if ((tableDataEmpty && state === LoadingState.Done) || state === LoadingState.Error) {
    const noData = typeof noDataMessage === 'function' ? noDataMessage() : noDataMessage;

    tableStateInfo = noData && (
      <Text variant="h4" color="secondary">
        {noData}
      </Text>
    );
  }

  return <div className={styles.tableStateContainer}>{tableStateInfo}</div>;
}

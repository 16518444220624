import { promql, Expression, MatchingOperator } from 'tsqtsq';

import { ConfigOverrideRule, FieldColorModeId, FieldConfigProperty, FieldMatcherID } from '@grafana/data';
import { SceneQueryRunner, VizPanel, VizPanelExploreButton } from '@grafana/scenes';
import { GraphDrawStyle, GraphGradientMode, LineInterpolation } from '@grafana/schema';
import { PointVisibility } from '@grafana/ui';

import { PROMETHEUS_DS } from 'components/common/variables';
import { DEFAULT_FILL_OPACITY } from 'constants/panels';
import { LIGHT_GREEN_COLOR, ORANGE_COLOR, PURPLE_COLOR } from 'utils/colors';

function getData(job: string, digest: string, schema: string, instance: string) {
  const statementsRowsExaminedTotal =
    new Expression({
      metric: 'mysql_perf_schema_events_statements_rows_examined_total',
      values: { job, instance, schema, digest },
      defaultOperator: MatchingOperator.equal,
    }).toString() + ' or on() vector(0)';

  const statementsRowsSentTotal =
    new Expression({
      metric: 'mysql_perf_schema_events_statements_rows_sent_total',
      values: { job, instance, schema, digest },
      defaultOperator: MatchingOperator.equal,
    }).toString() + ' or on() vector(0)';

  const statementsRowsAffectedTotal =
    new Expression({
      metric: 'mysql_perf_schema_events_statements_rows_affected_total',
      values: { job, instance, schema, digest },
      defaultOperator: MatchingOperator.equal,
    }).toString() + ' or on() vector(0)';

  const statementsTotal =
    new Expression({
      metric: 'mysql_perf_schema_events_statements_total',
      values: { job, instance, schema, digest },
      defaultOperator: MatchingOperator.equal,
    }).toString() + ' or on() vector(0)';

  return new SceneQueryRunner({
    datasource: PROMETHEUS_DS,
    queries: [
      {
        refId: 'Rows Scanned',
        expr: `(
          (
            ${promql.rate({
              expr: promql.sum({ expr: statementsRowsExaminedTotal }),
              interval: '$__rate_interval:',
            })}
            ) / (
              ${promql.rate({
                expr: promql.sum({ expr: statementsTotal }),
                interval: '$__rate_interval:',
              })}
          )
        )`,
        legendFormat: 'Rows Scanned',
      },
      {
        refId: 'Rows Returned',
        expr: `(
          (
            ${promql.rate({
              expr: promql.sum({ expr: statementsRowsSentTotal }),
              interval: '$__rate_interval:',
            })}
            ) / (
              ${promql.rate({
                expr: promql.sum({ expr: statementsTotal }),
                interval: '$__rate_interval:',
              })}
          )
        )`,
        legendFormat: 'Rows Returned',
      },
      {
        refId: 'Rows Affected',
        expr: `(
          (
            ${promql.rate({
              expr: promql.sum({ expr: statementsRowsAffectedTotal }),
              interval: '$__rate_interval:',
            })}
            ) / (
              ${promql.rate({
                expr: promql.sum({ expr: statementsTotal }),
                interval: '$__rate_interval:',
              })}
          )
        )`,
        legendFormat: 'Rows Affected',
      },
    ],
  });
}

export function rowsPanel(job: string, digest: string, schema: string, instance: string) {
  const baseOverrides: ConfigOverrideRule[] = [
    {
      matcher: { id: FieldMatcherID.byName, options: 'Rows Scanned' },
      properties: [
        {
          id: FieldConfigProperty.Color,
          value: {
            mode: FieldColorModeId.Fixed,
            fixedColor: ORANGE_COLOR,
          },
        },
      ],
    },
    {
      matcher: { id: FieldMatcherID.byName, options: 'Rows Returned' },
      properties: [
        {
          id: FieldConfigProperty.Color,
          value: {
            mode: FieldColorModeId.Fixed,
            fixedColor: LIGHT_GREEN_COLOR,
          },
        },
      ],
    },
    {
      matcher: { id: FieldMatcherID.byName, options: 'Rows Affected' },
      properties: [
        {
          id: FieldConfigProperty.Color,
          value: {
            mode: FieldColorModeId.Fixed,
            fixedColor: PURPLE_COLOR,
          },
        },
      ],
    },
  ];

  return new VizPanel({
    title: 'Rows',
    pluginId: 'timeseries',
    headerActions: [new VizPanelExploreButton()],
    $data: getData(job, digest, schema, instance),

    options: {
      tooltip: { mode: 'multi' },
    },

    fieldConfig: {
      defaults: {
        min: 0,
        unit: 'rows/s',
        color: {
          mode: FieldColorModeId.Fixed,
          fixedColor: ORANGE_COLOR,
        },
        custom: {
          fillOpacity: DEFAULT_FILL_OPACITY,
          spanNulls: true,
          drawStyle: GraphDrawStyle.Line,
          gradientMode: GraphGradientMode.Opacity,
          lineInterpolation: LineInterpolation.Smooth,
          showPoints: PointVisibility.Never,
        },
      },
      overrides: baseOverrides,
    },
  });
}

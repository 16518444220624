import { promql, Expression, MatchingOperator } from 'tsqtsq';

import { FieldColorModeId } from '@grafana/data';
import { SceneQueryRunner, VizPanel, VizPanelExploreButton } from '@grafana/scenes';
import { GraphDrawStyle, GraphGradientMode, LineInterpolation } from '@grafana/schema';
import { PointVisibility } from '@grafana/ui';

import { JOB_NAME, PROMETHEUS_DS } from 'components/common/variables';
import { DEFAULT_FILL_OPACITY } from 'constants/panels';
import { transformQuery } from 'pages/transformQuery';
import { ORANGE_COLOR } from 'utils/colors';

import { getConfigurePanelBehavior } from './configurePanelBehavior';

function getQuery(groupBy: string[] | undefined) {
  const defaultValues: Record<string, string> = { job: JOB_NAME };

  const statementsTotalExpr = new Expression({
    metric: 'mysql_perf_schema_events_statements_total',
    values: defaultValues,
    defaultOperator: MatchingOperator.regexMatch,
  }).toString();

  const eventsStatementsSecondsTotalExpr = new Expression({
    metric: 'mysql_perf_schema_events_statements_seconds_total',
    values: defaultValues,
    defaultOperator: MatchingOperator.regexMatch,
  }).toString();

  return `
    (
      (
        ${promql.rate({
          expr: promql.sum({ by: groupBy, expr: `${eventsStatementsSecondsTotalExpr}` }),
          interval: '$__rate_interval:',
        })}
        ) /
        (
          ${promql.rate({
            expr: promql.sum({ by: groupBy, expr: `${statementsTotalExpr}` }),
            interval: '$__rate_interval:',
          })}
          )
          ) * 60 * 60`;
}

export function durationAvgPanel() {
  const queryRunner = new SceneQueryRunner({
    datasource: PROMETHEUS_DS,
    queries: [],
  });

  const configurePanelBehavior = getConfigurePanelBehavior(queryRunner, getQuery, 'OverviewDurationAvgPanel');

  return new VizPanel({
    title: 'Duration Avg',
    pluginId: 'timeseries',
    $data: queryRunner,
    headerActions: [new VizPanelExploreButton({ transform: transformQuery })],

    fieldConfig: {
      defaults: {
        min: 0,
        unit: 'ms',
        color: {
          mode: FieldColorModeId.Fixed,
          fixedColor: ORANGE_COLOR,
        },
        custom: {
          fillOpacity: DEFAULT_FILL_OPACITY,
          spanNulls: true,
          drawStyle: GraphDrawStyle.Line,
          gradientMode: GraphGradientMode.Opacity,
          lineInterpolation: LineInterpolation.Smooth,
          showPoints: PointVisibility.Always,
          hideFrom: {
            legend: true,
            tooltip: false,
            viz: false,
          },
        },
        displayName: 'Duration',
      },
      overrides: [],
    },

    $behaviors: [configurePanelBehavior],
  });
}

import { FieldColorModeId } from '@grafana/data';
import { VizPanel, VizPanelExploreButton } from '@grafana/scenes';

import { getOverviewSceneQueryRunner } from 'pages/Overview/queries/queries';
import { transformQuery } from 'pages/transformQuery';
import { statBackgroundColor } from 'pages/variables';

export function schemaStat() {
  return new VizPanel({
    title: 'Schema',
    description: 'Current number of schemas. This panel is not affected by changes in time range selection.',
    pluginId: 'stat',
    $data: getOverviewSceneQueryRunner('schemaStat'),
    headerActions: [new VizPanelExploreButton({ transform: transformQuery })],
    options: {
      legend: { showLegend: false },
      tooltip: { mode: 'multi' },
      graphMode: 'none',
      colorMode: 'background',
      instant: true,
    },

    fieldConfig: {
      overrides: [],
      defaults: {
        noValue: '0',
        color: {
          mode: FieldColorModeId.Fixed,
          fixedColor: statBackgroundColor,
        },
        custom: {
          fillOpacity: 10,
          gradientMode: 'none',
        },
      },
    },
  });
}

import { RawTimeRange, TimeRange } from '@grafana/data';
import { SceneTimeRange } from '@grafana/scenes';

const TIME_RANGE_SESSION_STORAGE = 'db-o11y-time-range';
const DEFAULT_TIME_RANGE = { from: 'now-1h', to: 'now' };

function addTimeRangeHandler(timeRange: SceneTimeRange, onTimeRangeChange?: (timeRange: TimeRange) => void) {
  timeRange.addActivationHandler(() => {
    const listener = timeRange.subscribeToState((newState) => {
      const { raw } = newState.value;
      const newTimeRange = { from: raw.from.toString(), to: raw.to.toString() };
      sessionStorage.setItem(TIME_RANGE_SESSION_STORAGE, JSON.stringify(newTimeRange));

      if (typeof onTimeRangeChange === 'function') {
        onTimeRangeChange(newState.value);
      }
    });

    return () => listener?.unsubscribe();
  });
}

export const makeTimeRange = (
  from?: string,
  to?: string,
  onTimeRangeChange?: (timeRange: TimeRange) => void,
  defaultValue: RawTimeRange = DEFAULT_TIME_RANGE
): SceneTimeRange => {
  const timeRangeStored = JSON.parse(sessionStorage.getItem(TIME_RANGE_SESSION_STORAGE) || '{}');

  const newFrom = from ?? (Boolean(timeRangeStored?.from) ? timeRangeStored?.from : defaultValue.from);
  const newTo = to ?? (Boolean(timeRangeStored?.to) ? timeRangeStored?.to : defaultValue.to);

  const sceneTimeRange = new SceneTimeRange({ from: newFrom, to: newTo });
  addTimeRangeHandler(sceneTimeRange, onTimeRangeChange);

  return sceneTimeRange;
};

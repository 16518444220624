import React from 'react';

import { AppRootProps } from '@grafana/data';
import { SceneApp, useSceneApp } from '@grafana/scenes';

import { MySqlPage } from 'pages/Overview';
import { RootPage } from 'pages/RootPage';

import { PluginPropsContext } from '../../utils/utils.plugin';

function getSceneApp() {
  return new SceneApp({
    pages: [
      MySqlPage(),
      // The order is very important, the root page should come last.
      RootPage(),
    ],
  });
}

function DbO11ySceneApp() {
  const scene = useSceneApp(getSceneApp);
  return <scene.Component model={scene} />;
}

export class App extends React.PureComponent<AppRootProps> {
  render() {
    return (
      <PluginPropsContext.Provider value={this.props}>
        <DbO11ySceneApp />
      </PluginPropsContext.Provider>
    );
  }
}

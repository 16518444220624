import React from 'react';

import { DataSourcePicker } from 'components/DataSourcePickers/DataSourcePicker';
import { LOGS_DS_KEY, DEFAULT_LOGS_DS } from 'components/DataSourcePickers/dataSrcUtils';
import { LOKI_DS_NAME, LOKI_DS_TYPE } from 'components/common/variables';

export function LokiPicker() {
  return (
    <DataSourcePicker
      label="Logs Datasource"
      type={LOKI_DS_TYPE}
      pluginId={LOKI_DS_NAME}
      storageKey={LOGS_DS_KEY}
      defaultDataSource={DEFAULT_LOGS_DS}
      queryParamKey="var-lokiDatasource"
    />
  );
}

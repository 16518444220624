import { DataSourceSettings } from '@grafana/data';

export const LOGS_DS_KEY = 'grafanacloud.dbo11y.logsdatasource';
export const PROM_DS_KEY = 'grafanacloud.dbo11y.promdatasource';
export const DEFAULT_PROM_DS = 'grafanacloud-prom';
export const DEFAULT_LOGS_DS = 'grafanacloud-logs';

export function saveDataSourceSelectionToLocalStorage(key: string, value: string) {
  localStorage.setItem(key, value);
}

export function preselectDataSource(
  dataSources: DataSourceSettings[],
  localStorageKey: string,
  defaultDatasource: string
): string | null {
  const storedDataSource = localStorage.getItem(localStorageKey);
  let selectedDs = null;

  if (storedDataSource) {
    selectedDs = dataSources.find((ds) => ds.uid === storedDataSource);
  }

  if (!selectedDs && defaultDatasource) {
    selectedDs = dataSources.find((ds) => ds.uid === defaultDatasource);
  }

  if (!selectedDs && dataSources.length > 0) {
    selectedDs = dataSources[0];
  }

  const firstOption = selectedDs?.uid;
  if (firstOption) {
    saveDataSourceSelectionToLocalStorage(localStorageKey, firstOption);
    return firstOption;
  }

  return null;
}

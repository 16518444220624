import { SceneFlexLayout, SceneFlexItem } from '@grafana/scenes';

import { instanceStat } from './instanceStat';
import { schemaStat } from './schemaStat';
import { tableStat } from './tableStat';
import { timesCalledStat } from './timesCalledStat';

export function metaDataRow(job: string, digest: string, schema: string, instance: string) {
  return new SceneFlexLayout({
    direction: 'row',
    children: [
      new SceneFlexItem({
        height: 105,
        width: '25%',
        body: instanceStat(instance),
      }),
      new SceneFlexItem({
        height: 105,
        width: '25%',
        body: schemaStat(schema),
      }),
      new SceneFlexItem({
        height: 105,
        width: '25%',
        body: tableStat(digest, schema),
      }),
      new SceneFlexItem({
        height: 105,
        width: '25%',
        body: timesCalledStat(job, digest, schema, instance),
      }),
    ],
  });
}

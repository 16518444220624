import React from 'react';

import { CellProps, TextLink } from '@grafana/ui';

import { formatValue, formatValueWithDynamicUnit, formatDuration } from 'components/interactiveTable/helpers';
import { ROUTES } from 'utils/constants';
import { encodeInstance } from 'utils/encoding';
import { encodeURIParameter, prefixRoute } from 'utils/routing';

export const ExecutionTimeCell = ({ cell }: { cell: any }) => {
  const formattedValue = formatValueWithDynamicUnit(cell.value);

  return <div>{formattedValue}</div>;
};

export const RateTimeCell = ({ cell }: { cell: any }) => {
  const formattedValue = formatDuration(cell.value);

  return <div>{formattedValue}</div>;
};

export const NumberCell = ({ cell }: { cell: any }) => {
  if (Array.isArray(cell.value)) {
    const formattedValues = cell.value.map((value: number) => formatValue(value)).join(', ');
    return <div>{formattedValues}</div>;
  }

  const formattedValue = formatValue(cell.value);
  return <div>{formattedValue}</div>;
};

export const TimesCalled = ({ cell }: { cell: { value: number | number[] } }) => {
  const formatValue = (value: number): string => {
    const roundedValue = Math.round(value);
    return new Intl.NumberFormat(navigator.language).format(roundedValue);
  };

  if (Array.isArray(cell.value)) {
    const formattedValues = cell.value.map((value) => formatValue(value)).join(', ');
    return <div>{formattedValues}</div>;
  }

  const formattedValue = formatValue(cell.value);
  return <div>{formattedValue}</div>;
};

export const QueryCell = (props: CellProps<any>) => {
  const instance = props.row.values.instance;
  const schema = props.row.values.schema;
  const queryValue = props.cell.value;
  const digest = props.row.values.digest;

  const linkHref = `${prefixRoute(ROUTES.MySQL)}/${encodeInstance(instance)}/${encodeURIParameter(schema)}/${encodeURIParameter(digest)}`;

  const truncatedText = queryValue.length > 35 ? `${queryValue.substring(0, 35)}...` : queryValue;

  return (
    <TextLink href={linkHref} title={queryValue}>
      {truncatedText}
    </TextLink>
  );
};

export const TruncatedTextCell = ({ cell }: { cell: any }) => {
  const text = cell.value;
  const truncatedText = text.length > 35 ? `${text.substring(0, 35)}...` : text;

  return (
    <div title={text} style={{ cursor: 'pointer' }}>
      {truncatedText}
    </div>
  );
};

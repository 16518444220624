import { FieldColorModeId } from '@grafana/data';
import { VizPanel, VizPanelExploreButton } from '@grafana/scenes';

import { getOverviewSceneQueryRunner } from 'pages/Overview/queries/queries';
import { transformQuery } from 'pages/transformQuery';
import { statBackgroundColor } from 'pages/variables';

export function rateStat() {
  return new VizPanel({
    title: 'Current rate',
    pluginId: 'stat',
    $data: getOverviewSceneQueryRunner('currentRateStat'),
    headerActions: [new VizPanelExploreButton({ transform: transformQuery })],
    options: {
      legend: { showLegend: false },
      tooltip: { mode: 'multi' },
      graphMode: 'none',
      colorMode: 'background',
      instant: true,
    },

    fieldConfig: {
      overrides: [],
      defaults: {
        color: {
          mode: FieldColorModeId.Fixed,
          fixedColor: statBackgroundColor,
        },
        unit: 'qps',
      },
    },
  });
}

import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type UpdateQueryParamsOptions = {
  avoidUpdateIfParamExists?: boolean;
};

export const useQueryParams = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const updateQueryParam = useCallback(
    (
      paramName: string,
      paramValue: string,
      { avoidUpdateIfParamExists }: UpdateQueryParamsOptions = { avoidUpdateIfParamExists: false }
    ) => {
      const shouldSetParam = (avoidUpdateIfParamExists && params.get(paramName) === null) || !avoidUpdateIfParamExists;

      if (paramValue) {
        params.set(paramName, paramValue);
      } else {
        params.delete(paramName);
      }

      if (shouldSetParam) {
        history.replace({ pathname: location.pathname, search: params.toString() });
      }
    },
    [location.pathname, history, params]
  );
  return {
    updateQueryParam,
    queryParams: params,
  };
};

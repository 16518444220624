import { AdHocVariableFilter, MetricFindValue } from '@grafana/data';
import { getDataSourceSrv } from '@grafana/runtime';
import { AdHocFiltersVariable, VariableValueControl } from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';

import { PROMETHEUS_DS, SCENES_APP_FILTERS_VAR } from 'components/common/variables';

const DEFAULT_KEYS = [
  {
    text: 'schema',
    value: 'schema',
  },
  {
    text: 'instance',
    value: 'instance',
  },
];

export function makeAdHocFilters(onFilterAdd?: () => void): AdHocFiltersVariable {
  return new AdHocFiltersVariable({
    name: SCENES_APP_FILTERS_VAR,
    key: SCENES_APP_FILTERS_VAR,
    label: 'Filters',
    datasource: PROMETHEUS_DS,
    hide: VariableHide.hideVariable,
    allowCustomValue: false,
    defaultKeys: DEFAULT_KEYS,
    supportsMultiValueOperators: true,

    getTagKeysProvider: async (set: AdHocFiltersVariable) => {
      try {
        const ds = await getDataSourceSrv().get(set.state.datasource);

        if (!ds || !ds.getTagValues) {
          return { values: [] };
        }

        const keys = DEFAULT_KEYS.map((key) => ({
          text: key.text,
          value: key.value,
        })).filter((key) => set.state.filters.every((f) => f.key !== key.value));

        onFilterAdd?.();

        return { values: keys, replace: true };
      } catch (error) {
        return { values: [] };
      }
    },

    getTagValuesProvider: async (set: AdHocFiltersVariable, filter: AdHocVariableFilter) => {
      try {
        const ds = await getDataSourceSrv().get(set.state.datasource);

        if (!ds || !ds.getTagValues) {
          return { replace: false, values: [] };
        }
        const response = await ds.getTagValues({ key: filter.key, filters: set.state.filters });

        const metricValues: MetricFindValue[] = Array.isArray(response)
          ? response.map((val) => ({
              text: typeof val === 'object' && 'text' in val ? val.text : String(val),
              value: typeof val === 'object' && 'value' in val ? val.value : String(val),
            }))
          : response.data.map((val: any) => ({
              text: val.text || String(val),
              value: val.value || String(val),
            }));

        return {
          replace: true,
          values: metricValues,
        };
      } catch (error) {
        return { replace: false, values: [] };
      }
    },
  });
}

export function makeAdHocFiltersControl() {
  return new VariableValueControl({ variableName: SCENES_APP_FILTERS_VAR });
}

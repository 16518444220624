import React from 'react';

import { DataSourcePicker } from 'components/DataSourcePickers/DataSourcePicker';
import { DEFAULT_PROM_DS, PROM_DS_KEY } from 'components/DataSourcePickers/dataSrcUtils';
import { PROMETHEUS_DS_NAME, PROMETHEUS_DS_TYPE } from 'components/common/variables';

export function PrometheusPicker() {
  return (
    <DataSourcePicker
      label="Datasource"
      type={PROMETHEUS_DS_TYPE}
      pluginId={PROMETHEUS_DS_NAME}
      storageKey={PROM_DS_KEY}
      defaultDataSource={DEFAULT_PROM_DS}
      queryParamKey="var-datasource"
    />
  );
}

import React from 'react';

import { ROUTES } from 'utils/constants';
import { encodeRFC3986URI } from 'utils/encoding';
import { filterRoute } from 'utils/routing';

import { QueryDetailsStat } from './QueryDetailsStat';
import { linkStatStyleOverrides } from './metadataRowStyleOverrides';

export function instanceStat(instance: string) {
  // Because the filters seem to use RFC3986 encoding (they encode brackets which are part of our instance names)

  return new QueryDetailsStat({
    title: 'Instance',
    value: instance,
    valueFormatter: (value: string) => (
      <a href={filterRoute(ROUTES.MySQL, 'instance', encodeRFC3986URI(value))}>{value}</a>
    ),
    valueStyleOverrides: linkStatStyleOverrides,
  });
}

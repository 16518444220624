import { DataSourceVariable } from '@grafana/scenes';

export const VAR_DATASOURCE = '$datasource';
export const VAR_LOKI_DATASOURCE = '$lokiDatasource';

export const JOB_NAME = 'integrations/db-o11y';
export const PROMETHEUS_DS_NAME = 'prometheus';
export const LOKI_DS_NAME = 'loki';

export const PROMETHEUS_DS_TYPE = 'prometheus';
export const LOKI_DS_TYPE = 'loki';

export const getDatasourceVariable = () =>
  new DataSourceVariable({
    name: 'datasource',
    label: 'Datasource',
    pluginId: PROMETHEUS_DS_NAME,
  });

export const getLokiDatasource = () =>
  new DataSourceVariable({
    name: 'lokiDatasource',
    label: 'Logs Datasource',
    pluginId: LOKI_DS_NAME,
  });

export const PROMETHEUS_DS = {
  type: PROMETHEUS_DS_TYPE,
  uid: VAR_DATASOURCE,
};

export const SCENES_APP_FILTERS_VAR = 'filters';
export const SCENES_APP_GROUP_BY_VAR = 'groupBy';
export const SCENES_APP_SEARCH_VAR = 'search';

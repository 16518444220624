import React, { useEffect, useState, useCallback } from 'react';

import { DataSourceInstanceSettings, DataSourceSettings } from '@grafana/data';
import { DataSourcePicker as GrafanaDataSourcePicker } from '@grafana/runtime';
import { Icon, InlineFieldRow, useStyles2 } from '@grafana/ui';

import { useQueryParams } from 'components/common/helpers/useQueryParams';

import { DS_TYPE, getDataSourceList } from '../common/helpers/getDataSourceList';

import { getStyles } from './DataSrcPicker.styles';
import { preselectDataSource, saveDataSourceSelectionToLocalStorage } from './dataSrcUtils';

interface Props {
  label: string;
  type: DS_TYPE;
  pluginId: string;
  storageKey: string;
  defaultDataSource: string;
  queryParamKey: string;
}

export function DataSourcePicker({ label, type, pluginId, storageKey, defaultDataSource, queryParamKey }: Props) {
  const [dataSourceName, setDataSourceName] = useState<string | null>(null);
  const [dataSources, setDataSources] = useState<DataSourceSettings[]>([]);

  const { updateQueryParam, queryParams } = useQueryParams();
  const styles = useStyles2(getStyles);

  const handleQueryParamUpdate = useCallback(
    (key: string, value: string | null) => {
      if (value !== null && queryParams.get(key) !== value) {
        updateQueryParam(key, value);
      }
    },
    [queryParams, updateQueryParam]
  );

  useEffect(() => {
    async function fetchDataSources() {
      try {
        const dataSources = await getDataSourceList(type);
        setDataSources(dataSources);

        const initialValue = preselectDataSource(dataSources, storageKey, defaultDataSource);
        setDataSourceName(initialValue);

        if (initialValue) {
          handleQueryParamUpdate(queryParamKey, initialValue);
        }

        if (!queryParams.get(queryParamKey)) {
          handleQueryParamUpdate(queryParamKey, defaultDataSource);
        }
      } catch (error) {
        console.error(
          `Failed to fetch data sources for type ${type}: ${error instanceof Error ? error.message : error}`
        );
      }
    }

    fetchDataSources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, storageKey, defaultDataSource, queryParamKey, handleQueryParamUpdate]);

  if (!dataSources.length) {
    return (
      <div className={styles.notSupported}>
        <Icon className={styles.icon} size="lg" name="exclamation-triangle" />
        No {label} data sources found.
      </div>
    );
  }

  const handleDataSourceChange = (ds: DataSourceInstanceSettings) => {
    setDataSourceName(ds.uid);
    saveDataSourceSelectionToLocalStorage(storageKey, ds.uid);
    handleQueryParamUpdate(queryParamKey, ds.uid);
  };

  return (
    <div className={styles.root}>
      <InlineFieldRow className={styles.row}>
        <GrafanaDataSourcePicker
          noDefault={false}
          width={35}
          current={dataSourceName}
          type={type}
          pluginId={pluginId}
          onChange={handleDataSourceChange}
        />
      </InlineFieldRow>
    </div>
  );
}

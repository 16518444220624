import { cx } from '@emotion/css';
import React, { ReactNode, useMemo, useState, useCallback } from 'react';

import { SceneComponentProps, sceneGraph, SceneObject, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { useStyles2 } from '@grafana/ui';

import { getStyles } from './QueryDetailsStat.styles';

interface QueryDetailsStatState extends SceneObjectState {
  title: string;
  headerActions: React.ReactNode | SceneObject | SceneObject[];
  value?: string;
  valueFormatter?: (value: string) => React.ReactNode;
  valueStyleOverrides?: string;
}

export class QueryDetailsStat extends SceneObjectBase<QueryDetailsStatState> {
  public constructor(state?: Partial<QueryDetailsStatState>) {
    super({
      title: state?.title ?? '',
      headerActions: state?.headerActions,
      value: state?.value,
      valueFormatter: state?.valueFormatter,
      // style overrides give us more control of the style of the value
      // so we can keep the colors consistent. e.g. the elipses when overflowing
      valueStyleOverrides: state?.valueStyleOverrides,
      ...state,
    });
  }
  static Component = QueryDetailsStatRenderer;
}

function isSceneObjectWithComponent(item: ReactNode | SceneObject): item is SceneObject {
  return !!(item as SceneObject)?.Component;
}

function QueryDetailsStatRenderer({ model }: SceneComponentProps<QueryDetailsStat>) {
  const styles = useStyles2(getStyles);

  const { title, value, headerActions, valueFormatter, valueStyleOverrides } = model.useState();
  const dataState = sceneGraph.getData(model).useState();

  const isLoading = !dataState || !dataState.data;
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = useCallback(() => setDropdownOpen((prev) => !prev), []);
  const closeDropdown = useCallback(() => setDropdownOpen(false), []);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter' || event.key === ' ') {
        toggleDropdown();
      }
    },
    [toggleDropdown]
  );

  const normalizeToArray = useCallback((input?: string | number | string[]): string[] => {
    if (typeof input === 'number') {
      return [new Intl.NumberFormat(navigator.language).format(Math.ceil(input))];
    }
    if (typeof input === 'string') {
      return input.split(',').map((item) => {
        const numericValue = parseFloat(item.trim());

        return !isNaN(numericValue)
          ? new Intl.NumberFormat(navigator.language).format(Math.ceil(numericValue))
          : item.trim();
      });
    }
    if (Array.isArray(input)) {
      return input.map((item) => {
        const numericValue = parseFloat(item.toString().trim());

        return !isNaN(numericValue)
          ? new Intl.NumberFormat(navigator.language).format(Math.ceil(numericValue))
          : item.toString().trim();
      });
    }
    return [];
  }, []);

  const dataValue = useMemo(() => {
    if (isLoading || !dataState?.data?.series) {
      return [];
    }
    const series = dataState.data.series;
    if (series.length > 0) {
      const fields = series[0].fields;
      if (fields.length > 0) {
        return Array.from(fields[fields.length - 1].values || []);
      }
    }
    return [];
  }, [dataState, isLoading]);

  const displayValue = useMemo(() => {
    if (isLoading) {
      return ['Loading...'];
    }
    if (value) {
      return normalizeToArray(value);
    }
    if (dataValue.length > 0) {
      return normalizeToArray(dataValue);
    }
    return ['No data'];
  }, [value, dataValue, isLoading, normalizeToArray]);

  const visibleValues = displayValue.slice(0, 2);
  const hiddenValues = displayValue.slice(2);
  const hiddenCount = hiddenValues.length;

  return (
    <div className={styles.container} data-testid={`${title}-statPanel`}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <div>
          {Array.isArray(headerActions) ? (
            headerActions.map((action, index) =>
              React.isValidElement(action) ? (
                action
              ) : isSceneObjectWithComponent(action) ? (
                <action.Component key={index} model={action} />
              ) : null
            )
          ) : React.isValidElement(headerActions) ? (
            headerActions
          ) : isSceneObjectWithComponent(headerActions) ? (
            <headerActions.Component model={headerActions} />
          ) : null}
        </div>
      </div>

      <div className={styles.value}>
        <h2 className={cx(styles.valueText, valueStyleOverrides)} title={displayValue.join(', ')}>
          {visibleValues.map((v, index) => {
            const formattedValue = valueFormatter ? valueFormatter(v) : v;
            return (
              <>
                {formattedValue} {index < visibleValues.length - 1 && ' ,'}
              </>
            );
          })}
          {hiddenCount > 0 && (
            <>
              <span
                className={styles.moreCount}
                onClick={toggleDropdown}
                onKeyDown={handleKeyDown}
                role="button"
                tabIndex={0}
              >
                +{hiddenCount} more
              </span>
              {isDropdownOpen && (
                <div
                  className={styles.dropdown}
                  onMouseLeave={closeDropdown}
                  onKeyDown={handleKeyDown}
                  role="menu"
                  tabIndex={0}
                >
                  {hiddenValues.map((hiddenTitle, index) => (
                    <div key={index} className={styles.dropdownItem}>
                      {hiddenTitle}
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </h2>
      </div>
    </div>
  );
}

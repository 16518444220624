import { PLUGIN_BASE_URL } from './constants';

// Prefixes the route with the base URL of the plugin
export function prefixRoute(route: string): string {
  return `${PLUGIN_BASE_URL}/${route}`;
}

// Applies query parameters to the route for the scenes filters to pick up
export const filterRoute = (route: string, filterKey: string, filterValue: string): string => {
  return `${prefixRoute(route)}?var-filters=${filterKey}${encodeURIComponent('|=|')}${filterValue}`;
};

export function encodeURIParameter(parameter: string): string {
  return encodeURIComponent(parameter).replace(
    /[!'()*]/g,
    (c: string) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
  );
}

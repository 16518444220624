import { css } from '@emotion/css';

export const getStyles = () => ({
  root: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  row: css({
    alignItems: 'baseline',
  }),
  notSupported: css({
    display: 'flex',
    alignItems: 'center',
    marginTop: ' 8px',
  }),
  icon: css({
    color: 'orange',
    marginRight: '5px',
  }),
});

import { promql } from 'tsqtsq';

import { DataTransformerID } from '@grafana/data';
import { SceneDataTransformer, SceneQueryRunner } from '@grafana/scenes';

import { metricWithLabels } from 'components/common/helpers';
import { VAR_DATASOURCE } from 'components/common/variables';
import { InteractiveTableViz } from 'components/interactiveTable/InteractiveTableViz';
import { InteractiveTableColumns } from 'components/interactiveTable/types';

import { NumberCell, QueryCell, RateTimeCell, ExecutionTimeCell, TruncatedTextCell, TimesCalled } from './Cells';
import { QueryDetailsRow } from './types';

const columns: InteractiveTableColumns = [
  { id: 'digest', header: '' },
  { id: 'digest_text', header: 'Query', cell: QueryCell, sortType: 'alphanumeric', width: '300px' },
  {
    id: 'schema',
    header: 'Schema',
    cell: TruncatedTextCell,
    sortType: 'alphanumeric',
  },
  {
    id: 'instance',
    header: 'Instance',
    cell: TruncatedTextCell,
    sortType: 'alphanumeric',
    tooltip: { content: 'The database instance where the query was executed.' },
  },
  {
    id: 'Value #ExecutionTime',
    header: 'Duration',
    cell: ExecutionTimeCell,
    sortType: 'number',
    tooltip: { content: 'The average query execution time.' },
  },
  {
    id: 'Value #TimesCalled',
    header: 'Calls',
    cell: TimesCalled,
    sortType: 'number',
    tooltip: { content: 'The number of times the query was executed.' },
  },
  {
    id: 'Value #Errors',
    header: 'Errors',
    cell: NumberCell,
    sortType: 'number',
    tooltip: { content: 'The number of errors encountered when executing the query.' },
  },
  {
    id: 'Value #QueryCount',
    header: 'Rate',
    cell: RateTimeCell,
    sortType: 'number',
    tooltip: { content: 'The average rate at which the query was called.' },
  },
  {
    id: 'Value #LockTime',
    header: 'Lock Time',
    cell: ExecutionTimeCell,
    sortType: 'number',
    tooltip: { content: 'The average time that this query spent waiting for table locks.' },
  },
  {
    id: 'Value #RowsExamined',
    header: 'Rows Scanned',
    cell: NumberCell,
    sortType: 'number',
    tooltip: { content: 'The average number of rows scanned by the query each time it is executed.' },
  },
  {
    id: 'Value #RowsSent',
    header: 'Rows Returned',
    cell: NumberCell,
    sortType: 'number',
    tooltip: { content: 'The average number of rows returned by the query each time it is executed.' },
  },
];

const queryOptions = { format: 'table', instant: true };

const statementsTotal = metricWithLabels('mysql_perf_schema_events_statements_total');

function metricPerDigestText(metric: string) {
  return `${metricWithLabels(metric)} / on(digest_text, schema, instance) group_left ${statementsTotal}`;
}

const queries = [
  {
    refId: 'QueryCount',
    expr: promql.rate({ expr: statementsTotal }),
    by: ['schema', 'instance'],
    ...queryOptions,
  },
  {
    refId: 'TimesCalled',
    expr: promql.sum({
      expr: promql.increase({ expr: statementsTotal }),
      by: ['schema', 'instance', 'digest_text'],
    }),
    ...queryOptions,
  },
  {
    refId: 'ExecutionTime',
    expr: metricPerDigestText('mysql_perf_schema_events_statements_seconds_total'),
    ...queryOptions,
  },
  {
    refId: 'RowsSent',
    expr: metricPerDigestText('mysql_perf_schema_events_statements_rows_sent_total'),
    ...queryOptions,
  },
  {
    refId: 'RowsExamined',
    expr: metricPerDigestText('mysql_perf_schema_events_statements_rows_examined_total'),
    ...queryOptions,
  },
  {
    refId: 'Errors',
    expr: metricPerDigestText('mysql_perf_schema_events_statements_errors_total'),
    ...queryOptions,
  },
  {
    refId: 'LockTime',
    expr: metricPerDigestText('mysql_perf_schema_events_statements_lock_time_seconds_total'),
    ...queryOptions,
  },
];

const queryRunner = new SceneQueryRunner({
  datasource: {
    type: 'prometheus',
    uid: VAR_DATASOURCE,
  },
  queries,
});

const data = new SceneDataTransformer({
  $data: queryRunner,
  transformations: [
    {
      id: DataTransformerID.merge,
      options: {},
    },
    {
      id: DataTransformerID.filterByValue,
      options: {
        filters: [
          {
            fieldName: 'Value #TimesCalled',
            config: {
              id: 'equal',
              options: {
                value: '0',
              },
            },
          },
        ],
        type: 'exclude',
        match: 'any',
      },
    },
  ],
});

export const queryAnalysisTable = new InteractiveTableViz({
  columns,
  getRowId: (row: QueryDetailsRow) => {
    return `${row.digest_text}__${row.schema}__${row.instance}`;
  },

  $data: data,
  initialSortBy: [{ id: 'Value #ExecutionTime', desc: true }],
  pageSize: 10,
});

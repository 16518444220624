import { EmbeddedScene, SceneFlexLayout, SceneFlexItem } from '@grafana/scenes';

import { queryTextRow } from '../QueryTextPanel';
import { metaDataRow } from '../metadataRow';

import { durationPanel } from './durationPanel';
import { errorPanel } from './errorPanel';
import { ratePanel } from './ratePanel';
import { rowsPanel } from './rowsPanel';
import { waitPanel } from './waitPanel';

function redPanelRow(job: string, digest: string, schema: string, instance: string) {
  return new SceneFlexLayout({
    direction: 'row',
    children: [
      new SceneFlexItem({
        height: 300,
        body: ratePanel(job, digest, schema, instance),
      }),
      new SceneFlexItem({
        height: 300,
        body: errorPanel(job, digest, schema, instance),
      }),
      new SceneFlexItem({
        height: 300,
        body: durationPanel(job, digest, schema, instance),
      }),
    ],
  });
}

function rowsAndWaitRow(job: string, digest: string, schema: string, instance: string) {
  return new SceneFlexLayout({
    direction: 'row',
    children: [
      new SceneFlexItem({
        height: 300,
        body: rowsPanel(job, digest, schema, instance),
      }),
      new SceneFlexItem({
        height: 300,
        body: waitPanel(job, digest, schema, instance),
      }),
    ],
  });
}

export function getQueryPerformanceScene(job: string, digest: string, schema: string, instance: string) {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      direction: 'column',
      children: [
        queryTextRow(job, digest, schema, instance),
        metaDataRow(job, digest, schema, instance),
        redPanelRow(job, digest, schema, instance),
        rowsAndWaitRow(job, digest, schema, instance),
      ],
    }),
  });
}

import { css } from '@emotion/css';

// Can't use the usual `useStyles2` hook here because this is not a React component
const linkBlue = 'rgb(110, 159, 255)';

export const linkStatStyleOverrides = css({
  // Needed so that any overflow ellipsis have the same colour as the link
  color: linkBlue,
  ' a': {
    color: linkBlue,
  },
});

import {
  EmbeddedScene,
  SceneFlexLayout,
  SceneFlexItem,
  SceneVariableSet,
  SceneControlsSpacer,
  TextBoxVariable,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';

import { SCENES_APP_SEARCH_VAR } from 'components/common/variables';
import { SceneControlsNewLine } from 'components/scenesControlNewLine/SceneControlsNewLine';
import { queryAnalysisTable } from 'pages/Overview/QueryAnalysisTable';
import { makeAdHocFilters, makeAdHocFiltersControl } from 'pages/Overview/filters/makeAdHocFilters';
import { makeAdHocGroupByControl, makeGroupBy } from 'pages/Overview/groupBy/makeGroupBy';
import { redPanels } from 'pages/Overview/panels/redPanels';
import { SearchTextScene } from 'pages/Overview/search/SearchTextScene';
import { appOverviewStats } from 'pages/Overview/stats/appOverviewStats';

export function mySqlOverviewScene() {
  const adHocFiltersVariable = makeAdHocFilters();
  const groupByVariable = makeGroupBy();

  return new EmbeddedScene({
    $variables: new SceneVariableSet({
      variables: [
        groupByVariable,
        adHocFiltersVariable,
        new TextBoxVariable({
          name: SCENES_APP_SEARCH_VAR,
          hide: VariableHide.hideVariable,
        }),
      ],
    }),

    controls: [
      new SceneFlexLayout({
        direction: 'column',
        children: [
          new SceneFlexItem({
            key: 'search-text',
            body: new SearchTextScene({ searchTerm: '' }),
          }),
          new SceneControlsNewLine({
            controls: [makeAdHocGroupByControl(), makeAdHocFiltersControl(), new SceneControlsSpacer()],
          }),
        ],
      }),
    ],

    body: new SceneFlexLayout({
      direction: 'column',
      children: [
        new SceneFlexItem({
          body: appOverviewStats(),
        }),

        new SceneFlexItem({
          body: redPanels(),
        }),

        new SceneFlexItem({
          width: '100%',
          ySizing: 'content',
          body: queryAnalysisTable,
        }),
      ],
    }),
  });
}

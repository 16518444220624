import React from 'react';

import { ROUTES } from 'utils/constants';
import { filterRoute } from 'utils/routing';

import { QueryDetailsStat } from './QueryDetailsStat';
import { linkStatStyleOverrides } from './metadataRowStyleOverrides';

export function schemaStat(schema: string) {
  return new QueryDetailsStat({
    title: 'Schema',
    value: schema,
    valueFormatter: (value: string) => <a href={filterRoute(ROUTES.MySQL, 'schema', value)}>{value}</a>,
    valueStyleOverrides: linkStatStyleOverrides,
  });
}

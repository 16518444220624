import React, { useMemo } from 'react';
import { useDebounce } from 'react-use';

export function useDebouncedSearch(tableDataMemoized: any[] = []) {
  const [searchValue, setSearchValue] = React.useState('');
  const [debouncedSearchValue, setDebouncedSearchValue] = React.useState('');

  useDebounce(
    () => {
      setDebouncedSearchValue(searchValue);
    },
    250,
    [searchValue]
  );

  const { filteredTableData } = useMemo(() => {
    if (!debouncedSearchValue) {
      return { filteredTableData: tableDataMemoized };
    }

    const filtered = tableDataMemoized.filter((td) => {
      if (typeof td === 'object') {
        for (const cellValue of Object.values(td)) {
          if (cellValue == null) {
            continue;
          }
          if ((cellValue as any).toString().toLowerCase().includes(debouncedSearchValue.toLowerCase())) {
            return true;
          }
        }
      }
      return false;
    });

    return { filteredTableData: filtered };
  }, [debouncedSearchValue, tableDataMemoized]);

  return {
    searchValue,
    setSearchValue,
    filteredTableData,
  };
}

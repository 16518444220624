import { getDataSourceSrv } from '@grafana/runtime';
import { GroupByVariable, VariableValueControl } from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';

import { PROMETHEUS_DS, SCENES_APP_GROUP_BY_VAR } from 'components/common/variables';

const ALLOWED_KEYS = ['schema', 'instance'];

export function makeGroupBy(onGroupBy?: () => void): GroupByVariable {
  return new GroupByVariable({
    name: SCENES_APP_GROUP_BY_VAR,
    key: SCENES_APP_GROUP_BY_VAR,
    datasource: PROMETHEUS_DS,
    applyMode: 'manual',
    hide: VariableHide.hideVariable,
    label: 'Group by',
    isMulti: false,
    allowCustomValue: false,

    getTagKeysProvider: async (set: GroupByVariable) => {
      try {
        const ds = await getDataSourceSrv().get(set.state.datasource);
        if (!ds) {
          return { values: [] };
        }

        const keys = ALLOWED_KEYS.map((key) => ({
          text: key,
          value: key,
        }));

        onGroupBy?.();

        return { values: keys, replace: true };
      } catch (error) {
        return { values: [] };
      }
    },
  });
}

export function makeAdHocGroupByControl() {
  return new VariableValueControl({ variableName: SCENES_APP_GROUP_BY_VAR });
}

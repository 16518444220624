import { lastValueFrom } from 'rxjs';

import { DataSourceSettings } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';

import { LOKI_DS_TYPE, PROMETHEUS_DS_TYPE } from 'components/common/variables';

export type DS_TYPE = typeof PROMETHEUS_DS_TYPE | typeof LOKI_DS_TYPE;

// Regex to detect grafana cloud hosted datasources
const RE_GRAFANA_CLOUD_HOST = /grafana(-dev|-ops)?\.net/;

export async function getDataSourceList(type: DS_TYPE) {
  const res = await lastValueFrom(
    getBackendSrv().fetch<DataSourceSettings[]>({
      url: '/api/datasources',
      method: 'GET',
      headers: { accept: 'application/json, text/plain, */*' },
    })
  );

  return res.data.filter((ds) => RE_GRAFANA_CLOUD_HOST.test(ds.url) && ds.type === type);
}

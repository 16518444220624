import { promql } from 'tsqtsq';

import { SceneQueryRunner } from '@grafana/scenes';
import { DataQuery } from '@grafana/schema';

import { metricWithLabels } from 'components/common/helpers';
import { PROMETHEUS_DS } from 'components/common/variables';

enum QueryType {
  Range = 'range',
  Instant = 'instant',
}

interface DBQuery extends DataQuery {
  expr: string;
  legendFormat?: string;
  queryType?: string;
}

export const getOverviewSceneQueryRunner = (refId: string | string[]): SceneQueryRunner => {
  const queries: Record<string, DBQuery> = {
    instancesStat: {
      refId: 'Instances stat',
      expr: promql.count({
        expr: promql.count({
          expr: metricWithLabels('mysql_perf_schema_events_statements_total'),
          by: ['instance'],
        }),
      }),
      queryType: QueryType.Instant,
    },

    schemaStat: {
      refId: 'Schema stat',
      expr: promql.count({
        expr: promql.count({
          expr: metricWithLabels('mysql_perf_schema_events_statements_total'),
          by: ['schema'],
        }),
      }),
      queryType: QueryType.Instant,
    },

    totalQueriesExecutedStat: {
      refId: 'totalQueriesExecutedStat',
      expr: `round(${promql.sum({
        expr: promql.increase({
          expr: metricWithLabels('mysql_perf_schema_events_statements_total'),
        }),
      })})`,
      queryType: QueryType.Range,
    },

    currentRateStat: {
      refId: 'currentRateStat',
      expr: promql.sum({
        expr: promql.rate({
          expr: metricWithLabels('mysql_perf_schema_events_statements_total'),
          interval: '$__rate_interval',
        }),
      }),
      queryType: QueryType.Instant,
    },
  };

  return new SceneQueryRunner({
    datasource: PROMETHEUS_DS,
    queries: Array.isArray(refId) ? refId.map((id) => queries[id]) : [queries[refId]],
  });
};

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => ({
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: `1px solid ${theme.colors.border.weak}`,
    padding: theme.spacing(2),
    width: '100%',
  }),
  header: css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: theme.spacing(1.5),
  }),
  title: css({
    fontWeight: theme.typography.fontWeightBold,
  }),
  value: css({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  }),
  valueText: css({
    fontWeight: theme.typography.fontWeightBold,
    color: theme.colors.text.primary,
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  moreCount: css({
    fontSize: theme.typography.h5.fontSize,
    marginLeft: theme.spacing(0.5),
    cursor: 'pointer',
    color: theme.colors.text.link,
    position: 'relative',
  }),
  dropdown: css({
    position: 'absolute',
    top: '100%',
    right: 0,
    backgroundColor: theme.colors.background.primary,
    border: `1px solid ${theme.colors.border.weak}`,
    boxShadow: theme.shadows.z1,
    borderRadius: theme.shape.radius.default,
    padding: theme.spacing(1),
    zIndex: 1000,
  }),

  dropdownItem: css({
    cursor: 'pointer',
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1.25, 1.5),
    color: theme.colors.text.link,
    '&:hover': {
      backgroundColor: theme.colors.action.hover,
    },
  }),
});

import { EmbeddedScene, SceneFlexItem, SceneFlexLayout, VizPanel } from '@grafana/scenes';

import { queryTextRow } from '../QueryTextPanel';
import { metaDataRow } from '../metadataRow';

export function getQuerySampleAndExecutionPlanScene(job: string, digest: string, schema: string, instance: string) {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      direction: 'column',
      children: [
        queryTextRow(job, digest, schema, instance),
        metaDataRow(job, digest, schema, instance),
        new SceneFlexItem({
          height: 500,
          body: new VizPanel({
            pluginId: 'text',
            options: { content: 'placeholder' },
          }),
        }),
      ],
    }),
  });
}

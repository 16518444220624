import React from 'react';
import { Redirect } from 'react-router-dom';

import { EmbeddedScene, SceneAppPage, SceneObjectBase } from '@grafana/scenes';

import { ROUTES } from 'utils/constants';
import { prefixRoute } from 'utils/routing';

class Redirection extends SceneObjectBase {
  static Component = () => <Redirect to={ROUTES.MySQL} />;
}

function rootScene() {
  return new EmbeddedScene({
    body: new Redirection({}),
  });
}

export function RootPage() {
  return new SceneAppPage({
    title: 'Databases',
    url: prefixRoute(''),
    getScene: rootScene,
  });
}

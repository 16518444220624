import { css } from '@emotion/css';
import React from 'react';
import { Expression, MatchingOperator } from 'tsqtsq';

import { GrafanaTheme2 } from '@grafana/data';
import {
  SceneComponentProps,
  SceneDataTransformer,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  SceneQueryRunner,
} from '@grafana/scenes';
import { CodeEditor, useStyles2 } from '@grafana/ui';

import { PROMETHEUS_DS } from 'components/common/variables';

function getData(job: string, digest: string, schema: string, instance: string) {
  const queryRunner = new SceneQueryRunner({
    datasource: PROMETHEUS_DS,
    queries: [
      {
        refId: 'Query statements',
        expr: new Expression({
          metric: 'mysql_perf_schema_events_statements_total',
          values: { job, instance, digest, schema },
          defaultOperator: MatchingOperator.equal,
        }).toString(),
        instant: true,
      },
    ],
  });

  return new SceneDataTransformer({
    $data: queryRunner,
    transformations: [
      {
        id: 'labelsToFields',
        options: {
          keepLabels: ['digest_text'],
          mode: 'rows',
        },
      },
      {
        id: 'organize',
        options: {
          excludeByName: {
            label: true,
          },
          includeByName: {},
          indexByName: {},
        },
      },
    ],
  });
}

export function queryTextPanel(job: string, digest: string, schema: string, instance: string) {
  return new QueryTextPanel({
    $data: getData(job, digest, schema, instance),
  });
}

class QueryTextPanel extends SceneObjectBase<SceneObjectState> {
  public constructor(state: Partial<SceneObjectState>) {
    super(state);
  }
  static Component = QueryTextPanelRenderer;
}

const getStyles = (theme: GrafanaTheme2) => ({
  container: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  title: css({
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(0.5),
  }),
});

function QueryTextPanelRenderer(props: SceneComponentProps<QueryTextPanel>) {
  const styles = useStyles2(getStyles);

  const data = sceneGraph.getData(props.model).useState();
  const dataValue =
    data.data && data.data?.series.length > 0 && data.data?.series[0].fields.length > 0
      ? data.data.series[0].fields[0].values.toString()
      : '';

  return (
    <div className={styles.container}>
      <div className={styles.title}>SQL Query Text</div>
      <CodeEditor
        readOnly
        language="sql"
        value={dataValue}
        height={117}
        width="100%"
        monacoOptions={{
          wordWrap: 'on',
          overviewRulerLanes: 0,
          scrollBeyondLastLine: false,
          renderLineHighlight: 'none',
          minimap: { enabled: false },
          automaticLayout: true,
        }}
      />
    </div>
  );
}

import { SceneFlexItem, SceneFlexLayout } from '@grafana/scenes';

import { instancesStat, rateStat, totalQueriesExecutedStat, schemaStat, tablesStat } from 'pages/Overview/stats';

export function appOverviewStats() {
  return new SceneFlexLayout({
    direction: 'row',

    children: [
      new SceneFlexItem({
        height: 105,
        width: '20%',
        body: instancesStat(),
      }),
      new SceneFlexItem({
        height: 105,
        width: '20%',
        body: schemaStat(),
      }),
      new SceneFlexItem({
        height: 105,
        width: '20%',
        body: tablesStat(),
      }),
      new SceneFlexItem({
        height: 105,
        width: '20%',
        body: totalQueriesExecutedStat(),
      }),
      new SceneFlexItem({
        height: 105,
        width: '20%',
        body: rateStat(),
      }),
    ],
  });
}

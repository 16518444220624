import { promql, Expression, MatchingOperator } from 'tsqtsq';

import { FieldColorModeId } from '@grafana/data';
import { SceneQueryRunner, VizPanel, VizPanelExploreButton } from '@grafana/scenes';
import { GraphDrawStyle, GraphGradientMode, LineInterpolation } from '@grafana/schema';
import { PointVisibility } from '@grafana/ui';

import { PROMETHEUS_DS } from 'components/common/variables';

function getData(job: string, digest: string, schema: string, instance: string) {
  const statementsSecondsTotal =
    new Expression({
      metric: 'mysql_perf_schema_events_statements_seconds_total',
      values: { job, instance, digest, schema },
      defaultOperator: MatchingOperator.equal,
    }).toString() + ' or on() vector(0)';

  const statementsTotal =
    new Expression({
      metric: 'mysql_perf_schema_events_statements_total',
      values: { job, instance, digest, schema },
      defaultOperator: MatchingOperator.equal,
    }).toString() + ' or on() vector(0)';

  function quantile(job: string, digest: string, schema: string, instance: string, q: string) {
    return `(
      (${promql.rate({
        expr: new Expression({
          metric: 'mysql_perf_schema_events_statements_total',
          values: { job, instance, digest, schema },
          defaultOperator: MatchingOperator.equal,
        }).toString(),
        interval: '$__rate_interval',
      })}
        > bool 0
      ) * on()
      (${new Expression({
        metric: 'mysql_perf_schema_events_statements_latency',
        values: { job, instance, digest, schema, quantile: q },
        defaultOperator: MatchingOperator.equal,
      }).toString()})
      ) != 0`;
  }

  return new SceneQueryRunner({
    datasource: PROMETHEUS_DS,
    queries: [
      {
        refId: 'Duration AVG',
        expr: `(
          (
            ${promql.rate({
              expr: promql.sum({ expr: statementsSecondsTotal }),
              interval: '$__rate_interval:',
            })}
            ) / (
              ${promql.rate({
                expr: promql.sum({ expr: statementsTotal }),
                interval: '$__rate_interval:',
              })}
          )
        )`,
        legendFormat: 'AVG',
      },
      {
        refId: 'Duration P95',
        expr: quantile(job, digest, schema, instance, '95'),
        legendFormat: 'P95',
      },
      {
        refId: 'Duration P99',
        expr: quantile(job, digest, schema, instance, '99'),
        legendFormat: 'P99',
      },
    ],
  });
}

export function durationPanel(job: string, digest: string, schema: string, instance: string) {
  return new VizPanel({
    title: 'Duration',
    pluginId: 'timeseries',
    headerActions: [new VizPanelExploreButton()],
    $data: getData(job, digest, schema, instance),

    options: {
      tooltip: { mode: 'multi' },
    },

    fieldConfig: {
      defaults: {
        min: 0,
        unit: 's',
        color: {
          mode: FieldColorModeId.PaletteClassic,
        },
        custom: {
          fillOpacity: 5,
          spanNulls: true,
          drawStyle: GraphDrawStyle.Line,
          gradientMode: GraphGradientMode.None,
          lineInterpolation: LineInterpolation.Smooth,
          showPoints: PointVisibility.Never,
        },
      },
      overrides: [],
    },
  });
}

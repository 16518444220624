import { Expression, MatchingOperator } from 'tsqtsq';

import { JOB_NAME } from 'components/common/variables';

export function metricWithLabels(metric: string) {
  const defaultLabels = { job: JOB_NAME };

  return new Expression({
    metric,
    values: defaultLabels,
    defaultOperator: MatchingOperator.equal,
  }).toString();
}

import { SceneFlexItem, SceneFlexLayout } from '@grafana/scenes';

import { queryTextPanel } from './QueryTextPanel';

export function queryTextRow(job: string, digest: string, schema: string, instance: string) {
  return new SceneFlexLayout({
    direction: 'row',
    children: [
      new SceneFlexItem({
        height: 145,
        body: queryTextPanel(job, digest, schema, instance),
      }),
    ],
  });
}

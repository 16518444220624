import { css } from '@emotion/css';

import { InteractiveTableColumns } from 'components/interactiveTable/types';

const colWidthIndices: number[] = [];

export function getCustomColumnStyles(columnsMemoized: InteractiveTableColumns, isExpandable: boolean) {
  for (const [index, col] of columnsMemoized.entries()) {
    if (col?.width) {
      colWidthIndices.push(index);
    }
  }

  if (!colWidthIndices || colWidthIndices.length === 0) {
    return;
  }

  const customWidth = {} as any;

  if (isExpandable) {
    // ensure that the column which shows the expand icon is always visible
    customWidth[`thead > tr > th:nth-of-type(1)`] = css({
      width: '25px',
    });
  }

  const skipFirstTableHeader = 2;
  for (const colIndex of colWidthIndices) {
    const i = isExpandable ? skipFirstTableHeader + colIndex : colIndex + 1;

    customWidth[`thead > tr > th:nth-of-type(${i})`] = css({
      width: columnsMemoized[colIndex]?.width,
    });
  }

  return css(customWidth);
}

import { promql, Expression, MatchingOperator } from 'tsqtsq';

import { FieldColorModeId } from '@grafana/data';
import { SceneQueryRunner, VizPanel, VizPanelExploreButton } from '@grafana/scenes';
import { GraphDrawStyle, GraphGradientMode, LineInterpolation } from '@grafana/schema';
import { PointVisibility } from '@grafana/ui';

import { PROMETHEUS_DS } from 'components/common/variables';
import { DEFAULT_FILL_OPACITY } from 'constants/panels';
import { ERROR_COLOR } from 'utils/colors';

function getData(job: string, digest: string, schema: string, instance: string) {
  const statementsErrorsTotal =
    new Expression({
      metric: 'mysql_perf_schema_events_statements_errors_total',
      values: { job, instance, digest, schema },
      defaultOperator: MatchingOperator.equal,
    }).toString() + ' or on() vector(0)';

  const statementsTotal =
    new Expression({
      metric: 'mysql_perf_schema_events_statements_total',
      values: { job, instance, digest, schema },
      defaultOperator: MatchingOperator.equal,
    }).toString() + ' or on() vector(0)';

  return new SceneQueryRunner({
    datasource: PROMETHEUS_DS,
    queries: [
      {
        refId: 'Errors',
        expr: `(
          (
            ${promql.rate({
              expr: promql.sum({ expr: `${statementsErrorsTotal}` }),
              interval: '$__rate_interval:',
            })}
          ) / (
            ${promql.rate({
              expr: promql.sum({ expr: `${statementsTotal}` }),
              interval: '$__rate_interval:',
            })}
          )
        ) * 100`,
      },
    ],
  });
}

export function errorPanel(job: string, digest: string, schema: string, instance: string) {
  return new VizPanel({
    title: 'Errors',
    pluginId: 'timeseries',
    headerActions: [new VizPanelExploreButton()],
    $data: getData(job, digest, schema, instance),

    options: {
      legend: { showLegend: false },
    },

    fieldConfig: {
      defaults: {
        min: 0,
        unit: '%',
        color: {
          mode: FieldColorModeId.Fixed,
          fixedColor: ERROR_COLOR,
        },
        custom: {
          fillOpacity: DEFAULT_FILL_OPACITY,
          spanNulls: true,
          drawStyle: GraphDrawStyle.Line,
          gradientMode: GraphGradientMode.Opacity,
          lineInterpolation: LineInterpolation.Smooth,
          showPoints: PointVisibility.Never,
        },
        displayName: 'Errors',
      },
      overrides: [],
    },
  });
}

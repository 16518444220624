import { promql, Expression, MatchingOperator } from 'tsqtsq';

import { FieldColorModeId } from '@grafana/data';
import { SceneQueryRunner, VizPanel, VizPanelExploreButton } from '@grafana/scenes';
import { GraphDrawStyle, GraphGradientMode, LineInterpolation } from '@grafana/schema';
import { PointVisibility } from '@grafana/ui';

import { PROMETHEUS_DS } from 'components/common/variables';
import { DEFAULT_FILL_OPACITY } from 'constants/panels';
import { RATE_COLOR } from 'utils/colors';

function getData(job: string, digest: string, schema: string, instance: string) {
  const statementsTotal = new Expression({
    metric: 'mysql_perf_schema_events_statements_total',
    values: { job, instance, schema, digest },
    defaultOperator: MatchingOperator.equal,
  }).toString();

  return new SceneQueryRunner({
    datasource: PROMETHEUS_DS,
    queries: [
      {
        refId: 'QueryCount',
        expr: promql.sum({ expr: promql.rate({ expr: statementsTotal }) }),
      },
    ],
  });
}

export function ratePanel(job: string, digest: string, schema: string, instance: string) {
  return new VizPanel({
    title: 'Rate',
    pluginId: 'timeseries',
    headerActions: [new VizPanelExploreButton()],
    $data: getData(job, digest, schema, instance),
    options: {
      legend: { showLegend: false },
    },
    fieldConfig: {
      defaults: {
        min: 0,
        unit: 'req/s',
        color: {
          mode: FieldColorModeId.Fixed,
          fixedColor: RATE_COLOR,
        },
        custom: {
          fillOpacity: DEFAULT_FILL_OPACITY,
          spanNulls: true,
          drawStyle: GraphDrawStyle.Line,
          gradientMode: GraphGradientMode.Opacity,
          lineInterpolation: LineInterpolation.Smooth,
          showPoints: PointVisibility.Never,
        },
        displayName: 'Rate',
      },
      overrides: [],
    },
  });
}

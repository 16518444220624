import React from 'react';

import {
  behaviors,
  SceneAppPage,
  SceneAppPageLike,
  SceneReactObject,
  SceneRefreshPicker,
  SceneRouteMatch,
  SceneTimePicker,
  SceneVariableSet,
} from '@grafana/scenes';
import { DashboardCursorSync } from '@grafana/schema';

import { LokiPicker } from 'components/DataSourcePickers/LokiPicker';
import { PrometheusPicker } from 'components/DataSourcePickers/PrometheusPicker';
import { getDatasourceVariable, getLokiDatasource } from 'components/common/variables';
import { SceneControlsNewLine } from 'components/scenesControlNewLine/SceneControlsNewLine';
import { QueryDetailsPage } from 'pages/QueryDetails/QueryDetailsPage';
import { ROUTES } from 'utils/constants';
import { prefixRoute } from 'utils/routing';
import { makeTimeRange } from 'utils/timeRange';

import { mySqlOverviewScene } from './mySqlOverviewScene';

export function MySqlPage(from?: string, to?: string) {
  return new SceneAppPage({
    title: 'MySQL Queries Overview',
    titleImg: 'https://storage.googleapis.com/grafanalabs-integration-logos/mysql.png',
    url: prefixRoute(ROUTES.MySQL),
    getScene: mySqlOverviewScene,
    drilldowns: [
      {
        routePath: `${prefixRoute(ROUTES.MySQL)}/:instance/:schema/:digest`,
        getPage: (
          routeMatch: SceneRouteMatch<{ digest: string; schema: string; instance: string }>,
          parent: SceneAppPageLike
        ) => QueryDetailsPage(routeMatch, parent, from, to),
      },
    ],

    $variables: new SceneVariableSet({
      variables: [getDatasourceVariable(), getLokiDatasource()],
    }),

    preserveUrlKeys: ['from', 'to'],
    $timeRange: makeTimeRange(from, to),
    controls: [
      new SceneControlsNewLine({
        align: 'flex-end',
        controls: [
          new SceneReactObject({
            reactNode: React.createElement(PrometheusPicker),
          }),
          new SceneReactObject({
            reactNode: React.createElement(LokiPicker),
          }),
          new SceneTimePicker({ isOnCanvas: true }),
          new SceneRefreshPicker({ isOnCanvas: true }),
        ],
      }),
    ],

    $behaviors: [new behaviors.CursorSync({ key: 'sync1', sync: DashboardCursorSync.Tooltip })],
  });
}

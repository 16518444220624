import { ReactNode } from 'react';
import { SortingRule } from 'react-table';

import { PanelData } from '@grafana/data';
import { SceneObjectState } from '@grafana/scenes';
import { Column } from '@grafana/ui';

export enum SortDirection {
  Ascending = 'ascending',
  Descending = 'descending',
  None = 'none',
}

export type InteractiveTableColumns = Array<
  Omit<Column<any>, 'header'> & {
    width?: string;
    keepEmpty?: boolean;
    header: Column<any>['header'] | ReactNode | (() => Column<any>['header'] | ReactNode);
    tooltip?: {
      content: string;
    };
  }
>;

export interface InteractiveTableVizState extends SceneObjectState {
  /** Table's columns definition. */
  columns: InteractiveTableColumns;
  /** Number of rows per page. A value of zero disables pagination. Defaults to 0. */
  pageSize?: number;
  /** Custom no data message */
  noDataMessage?: string | (() => ReactNode);
  /** Custom loading message */
  loadingMessage?: string;
  /** Title of the table */
  title?: string;
  /** Hide the border surrounding the view */
  hideBorder?: boolean;
  /** Must return a unique id for each row */
  getRowId: (row: any) => string;
  /** Define the initial sort of the table */
  initialSortBy?: Array<SortingRule<any>>;
  /** Render function for the expanded row. if not provided, the tables rows will not be expandable. */
  expandedRowRenderer?: (row: any) => ReactNode;
  sortDirection?: SortDirection;
  sortedColumnId?: string;
}

export type TableStateInfoProps = {
  data?: PanelData;
  tableDataMemoized: any[];
  noDataMessage: InteractiveTableVizState['noDataMessage'];
  loadingMessage: InteractiveTableVizState['loadingMessage'];
};

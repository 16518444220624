import { promql, Expression, MatchingOperator } from 'tsqtsq';

import { FieldColorModeId } from '@grafana/data';
import { SceneQueryRunner, VizPanel, VizPanelExploreButton } from '@grafana/scenes';
import { GraphDrawStyle, GraphGradientMode, LineInterpolation } from '@grafana/schema';
import { PointVisibility } from '@grafana/ui';

import { VAR_DATASOURCE } from 'components/common/variables';
import { DEFAULT_FILL_OPACITY } from 'constants/panels';
import { ORANGE_COLOR } from 'utils/colors';

function getData(job: string, digest: string, schema: string, instance: string) {
  const statementsLockTimeTotal =
    new Expression({
      metric: 'mysql_perf_schema_events_statements_lock_time_seconds_total',
      values: { job, instance, digest, schema },
      defaultOperator: MatchingOperator.equal,
    }).toString() + ' or on() vector(0)';

  const statementsTotal =
    new Expression({
      metric: 'mysql_perf_schema_events_statements_total',
      values: { job, instance, digest, schema },
      defaultOperator: MatchingOperator.equal,
    }).toString() + ' or on() vector(0)';

  return new SceneQueryRunner({
    datasource: {
      type: 'prometheus',
      uid: VAR_DATASOURCE,
    },
    queries: [
      {
        refId: 'Lock Wait',
        expr: `(
          (
            ${promql.rate({
              expr: promql.sum({ expr: statementsLockTimeTotal }),
              interval: '$__rate_interval:',
            })}
            ) / (
              ${promql.rate({
                expr: promql.sum({ expr: statementsTotal }),
                interval: '$__rate_interval:',
              })}
          )
        )`,
        legendFormat: 'Lock Wait',
      },
    ],
  });
}

export function waitPanel(job: string, digest: string, schema: string, instance: string) {
  return new VizPanel({
    title: 'Wait Events',
    pluginId: 'timeseries',
    headerActions: [new VizPanelExploreButton()],
    $data: getData(job, digest, schema, instance),

    options: {
      tooltip: { mode: 'multi' },
    },

    fieldConfig: {
      defaults: {
        min: 0,
        unit: 's',
        color: {
          mode: FieldColorModeId.Fixed,
          fixedColor: ORANGE_COLOR,
        },
        custom: {
          fillOpacity: DEFAULT_FILL_OPACITY,
          spanNulls: true,
          drawStyle: GraphDrawStyle.Line,
          gradientMode: GraphGradientMode.Opacity,
          lineInterpolation: LineInterpolation.Smooth,
          showPoints: PointVisibility.Never,
        },
      },
      overrides: [],
    },
  });
}

export const formatDuration = (value: number) => {
  const absValue = Math.abs(value);

  if (absValue === 0) {
    return '0 req/s';
  } else if (absValue >= 1) {
    return `${formatValue(value)} req/s`;
  } else if (absValue >= 1 / 60) {
    return `${formatValue(value * 60)} req/min`;
  } else {
    return `${formatValue(value * 60 * 60)} req/h`;
  }
};

function isValid(value: number | undefined | null): value is number {
  const isInvalid = value === undefined || value === null || isNaN(value);
  return !isInvalid;
}

function nice(value: number) {
  // parseFloat removes any trailing zeros
  const fixedValue = parseFloat(value.toFixed(3));
  return fixedValue.toString();
}

// In the case where no value is present for a specific schema, the cell.value might be undefined or null
export const formatValue = (value: number | undefined | null) => {
  if (!isValid(value)) {
    return '-';
  }
  if (Number.isInteger(value)) {
    return value.toString();
  }
  return nice(value);
};

export const formatValueWithDynamicUnit = (value: number | undefined | null) => {
  if (!isValid(value)) {
    // Return dash with the smallest unit (`- ns`) for invalid values
    return '- ns';
  }

  const absValue = Math.abs(value);

  if (absValue === 0) {
    return '0 s';
  } else if (absValue >= 1) {
    return `${nice(value)} s`;
  } else if (absValue >= 0.001) {
    return `${nice(value * 1000)} ms`;
  } else if (absValue >= 0.000001) {
    return `${nice(value * 1000000)} µs`;
  } else {
    return `${nice(value * 1000000000)} ns`;
  }
};

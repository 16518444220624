import { getTemplateSrv } from '@grafana/runtime';
import { DataQuery } from '@grafana/schema';

import { SCENES_APP_FILTERS_VAR } from 'components/common/variables';

interface PrometheusQuery extends DataQuery {
  expr?: string;
}

export function transformQuery(query: DataQuery): DataQuery {
  const templateSrv = getTemplateSrv();

  const filterByValue = templateSrv.replace(`\${${SCENES_APP_FILTERS_VAR}}`).replace(/[{}]/g, '');

  const prometheusQuery = query as PrometheusQuery;

  if (filterByValue) {
    if (prometheusQuery.expr) {
      const filterPairs = filterByValue.split(',').map((pair) => pair.trim().split('='));

      const injectFilter = (match: string) => {
        const existingFilters = match
          .slice(1, -1)
          .split(',')
          .map((pair) => pair.trim());
        const newFilters = filterPairs.filter(([key]) => !existingFilters.some((filter) => filter.startsWith(key)));

        const additionalFilters = newFilters.map(([key, value]) => `${key}=${value}`).join(', ');
        return additionalFilters ? match.replace(/}$/, `, ${additionalFilters}}`) : match;
      };

      // Apply the filter to all PromQL expressions without duplicates
      prometheusQuery.expr = prometheusQuery.expr.replace(
        /(\{[^}]*\})/g, // Match each brace-enclosed filter block
        injectFilter
      );
    } else {
      // If no existing expression, create one with the filter
      prometheusQuery.expr = `{${filterByValue}}`;
    }
  }

  return prometheusQuery;
}

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export function getStyles(theme: GrafanaTheme2) {
  return {
    container: css({
      position: 'relative',
      maxHeight: 'fit-content',
      border: `1px solid ${theme.colors.border.medium}`,
      padding: theme.spacing(0, 1, 1, 1),
    }),
    hideBorder: css({
      border: 'none',
    }),
    tableContentWrapper: css({
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    }),

    table: css({
      width: '100%',
      minHeight: '300px',
      tableLayout: 'auto',
      'th:nth-child(1), td:nth-child(1)': {
        display: 'none',
      },
      '& td': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        padding: theme.spacing(1.5, 1),
      },
      '& tr:has([aria-expanded="true"]) ': {
        backgroundColor: theme.isDark ? 'rgb(30, 33, 37)' : 'rgb(247, 247, 247)',
      },
      '& tr th button::after': {
        content: 'none',
      },
    }),
    tableExpandedRowContainer: css({
      marginLeft: '2rem',
    }),
    tableControls: css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      margin: theme.spacing(-0.5, -1, 0, -1),

      ['> form']: css({
        marginLeft: 'auto',
      }),

      // The table state messages box is absolute positioned and spans the whole table container. We need to use z-index to allow hover events for the PanelStatus action
      // Good enough for now but long term we may re-org the table structure a bit to avoid this.
      zIndex: 1,
    }),
    noDataContainer: css({
      display: 'grid',
      placeItems: 'center',
      height: '100%',
      width: '100%',
      border: `1px solid ${theme.colors.border.weak}`,
      color: theme.colors.text.secondary,
      fontSize: theme.typography.h4.fontSize,
    }),
    loadingMessage: css({
      margin: 'auto',
      ...theme.typography.h1,
    }),
    tableStateContainer: css({
      position: 'relative',
      minHeight: '150px',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
  };
}

import { SceneFlexItem, SceneFlexLayout } from '@grafana/scenes';

import { ratePanel, errorsPanel, durationAvgPanel } from 'pages/Overview/panels';

export function redPanels() {
  return new SceneFlexLayout({
    direction: 'column',

    children: [
      new SceneFlexLayout({
        direction: 'row',
        children: [
          new SceneFlexItem({
            height: 300,
            body: ratePanel(),
          }),
          new SceneFlexItem({
            height: 300,
            body: errorsPanel(),
          }),
          new SceneFlexItem({
            height: 300,
            body: durationAvgPanel(),
          }),
        ],
      }),
    ],
  });
}

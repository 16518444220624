import { css } from '@emotion/css';
import React from 'react';

import { Icon, Tooltip, useStyles2 } from '@grafana/ui';

export function Title(props: { digest: string; schema: string; instance: string }) {
  const styles = useStyles2(getStyles);
  return (
    <h1 className={styles.title}>
      {props.digest}
      <Tooltip content={`This query is associated with the specified database instance and schema.`}>
        <Icon className={styles.titleInfoIcon} size="xl" name="info-circle" />
      </Tooltip>
    </h1>
  );
}

const getStyles = () => ({
  title: css({
    display: 'flex',
    alignItems: 'center',
  }),
  titleInfoIcon: css({
    marginLeft: '0.5rem',
  }),
});

import { isEqual } from 'lodash';
import React from 'react';
import { Expression, MatchingOperator } from 'tsqtsq';

import {
  behaviors,
  SceneAppPage,
  SceneAppPageLike,
  SceneDataTransformer,
  SceneQueryRunner,
  SceneReactObject,
  SceneRefreshPicker,
  SceneRouteMatch,
  SceneTimePicker,
  SceneVariableSet,
} from '@grafana/scenes';
import { DashboardCursorSync } from '@grafana/schema';

import { LokiPicker } from 'components/DataSourcePickers/LokiPicker';
import { PrometheusPicker } from 'components/DataSourcePickers/PrometheusPicker';
import { getDatasourceVariable, getLokiDatasource, JOB_NAME, PROMETHEUS_DS } from 'components/common/variables';
import { SceneControlsNewLine } from 'components/scenesControlNewLine/SceneControlsNewLine';
import { ROUTES } from 'utils/constants';
import { decodeInstance, encodeInstance } from 'utils/encoding';
import { encodeURIParameter, prefixRoute } from 'utils/routing';
import { makeTimeRange } from 'utils/timeRange';

import { Title } from './Title';
import { getQueryPerformanceScene } from './queryPerformance';
import { getQuerySampleAndExecutionPlanScene } from './querySampleAndExecutionPlan';
import { getTableSchemaDetailsScene } from './tableSchemaDetails';

function getData(job: string, digest: string, instance: string) {
  const queryRunner = new SceneQueryRunner({
    datasource: PROMETHEUS_DS,
    queries: [
      {
        refId: 'Statements',
        expr: new Expression({
          metric: 'mysql_perf_schema_events_statements_total',
          values: { job, instance, digest },
          defaultOperator: MatchingOperator.equal,
        }).toString(),
        instant: true,
      },
    ],
  });

  return new SceneDataTransformer({
    $data: queryRunner,
    transformations: [
      {
        id: 'labelsToFields',
        options: {
          keepLabels: ['digest_text'],
          mode: 'columns',
        },
      },
      {
        id: 'organize',
        options: {
          excludeByName: {},
          includeByName: {
            digest_text: true,
          },
          indexByName: {},
          renameByName: {},
        },
      },
    ],
  });
}

const TITLE_MAX_LENGTH = 24;

function getQueryPerformanceTab(
  routeMatch: SceneRouteMatch<{ digest: string; schema: string; instance: string }>,
  parent: SceneAppPageLike
) {
  const digest = decodeURIComponent(routeMatch.params.digest);
  const schema = decodeURIComponent(routeMatch.params.schema);
  const instance = decodeInstance(routeMatch.params.instance);

  return new SceneAppPage({
    title: 'Query Performance',
    url: `${prefixRoute(ROUTES.MySQL)}/${encodeInstance(instance)}/${encodeURIParameter(schema)}/${encodeURIParameter(digest)}/queryPerformance`,
    getScene: () => getQueryPerformanceScene(JOB_NAME, digest, schema, instance),
    getParentPage: () => parent,
  });
}

function getQuerySampleAndExecutionPlanTab(
  routeMatch: SceneRouteMatch<{ digest: string; schema: string; instance: string }>,
  parent: SceneAppPageLike
) {
  const digest = decodeURIComponent(routeMatch.params.digest);
  const schema = decodeURIComponent(routeMatch.params.schema);
  const instance = decodeInstance(routeMatch.params.instance);

  return new SceneAppPage({
    title: 'Query Sample and Execution Plan',
    url: `${prefixRoute(ROUTES.MySQL)}/${encodeInstance(instance)}/${encodeURIParameter(schema)}/${encodeURIParameter(digest)}/sampleExecutionPlan`,
    getScene: () => getQuerySampleAndExecutionPlanScene(JOB_NAME, digest, schema, instance),
    getParentPage: () => parent,
  });
}

function getTableSchemaDetailsTab(
  routeMatch: SceneRouteMatch<{ digest: string; schema: string; instance: string }>,
  parent: SceneAppPageLike
) {
  const digest = decodeURIComponent(routeMatch.params.digest);
  const schema = decodeURIComponent(routeMatch.params.schema);
  const instance = decodeInstance(routeMatch.params.instance);

  return new SceneAppPage({
    title: 'Table Schema Details',
    url: `${prefixRoute(ROUTES.MySQL)}/${encodeInstance(instance)}/${encodeURIParameter(schema)}/${encodeURIParameter(digest)}/tableSchema`,
    getScene: () => getTableSchemaDetailsScene(JOB_NAME, digest, schema, instance),
    getParentPage: () => parent,
  });
}

export function QueryDetailsPage(
  routeMatch: SceneRouteMatch<{ digest: string; schema: string; instance: string }>,
  parent: SceneAppPageLike,
  from?: string,
  to?: string
): SceneAppPage {
  const digest = decodeURIComponent(routeMatch.params.digest);
  const schema = decodeURIComponent(routeMatch.params.schema);
  const instance = decodeInstance(routeMatch.params.instance);
  const job = JOB_NAME;

  return new SceneAppPage({
    url: `${prefixRoute(ROUTES.MySQL)}/${encodeInstance(instance)}/${encodeURIComponent(schema)}/${encodeURIComponent(digest)}`,
    getParentPage: () => parent,
    title: digest,
    renderTitle: (title: string) => <Title digest={title} schema={schema} instance={instance} />,
    tabs: [
      getQueryPerformanceTab(routeMatch, parent),
      getQuerySampleAndExecutionPlanTab(routeMatch, parent),
      getTableSchemaDetailsTab(routeMatch, parent),
    ],
    controls: [
      new SceneControlsNewLine({
        align: 'flex-end',
        controls: [
          new SceneReactObject({
            reactNode: React.createElement(PrometheusPicker),
          }),
          new SceneReactObject({
            reactNode: React.createElement(LokiPicker),
          }),
          new SceneTimePicker({ isOnCanvas: true }),
          new SceneRefreshPicker({ isOnCanvas: true }),
        ],
      }),
    ],
    preserveUrlKeys: ['from', 'to'],
    $data: getData(job, digest, instance),
    $variables: new SceneVariableSet({ variables: [getDatasourceVariable(), getLokiDatasource()] }),
    $timeRange: makeTimeRange(from, to),
    $behaviors: [
      new behaviors.CursorSync({ key: 'sync1', sync: DashboardCursorSync.Tooltip }),
      (page: SceneAppPage) => {
        const unsubscribable = page.state.$data?.subscribeToState((newState, oldState) => {
          if (!isEqual(newState, oldState) && newState.data?.series[0]) {
            const title = newState.data?.series[0].fields[0].values[0] as string;
            const titleTrimmed = title.length < TITLE_MAX_LENGTH ? title : title.substring(0, TITLE_MAX_LENGTH) + '...';

            page.setState({ title: titleTrimmed });
          }
        });

        return () => unsubscribable?.unsubscribe();
      },
    ],
  });
}

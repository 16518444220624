import { FieldColorModeId } from '@grafana/data';
import { SceneQueryRunner, VizPanel, VizPanelExploreButton } from '@grafana/scenes';

import { JOB_NAME, VAR_LOKI_DATASOURCE } from 'components/common/variables';
import { statBackgroundColor } from 'pages/variables';

function getData() {
  return new SceneQueryRunner({
    datasource: {
      uid: VAR_LOKI_DATASOURCE,
      type: 'loki',
    },
    queries: [
      {
        refId: 'Tables',
        // logQL including the applied filters
        expr: `count(count_over_time({job="${JOB_NAME}"} | logfmt | op = \`table_detection\` $filters [15m]))`,
        instant: true,
      },
    ],
  });
}

export function tablesStat() {
  return new VizPanel({
    title: 'Tables',
    pluginId: 'stat',
    $data: getData(),
    headerActions: [new VizPanelExploreButton()],

    options: {
      legend: { showLegend: false },
      tooltip: { mode: 'multi' },
      graphMode: 'none',
      colorMode: 'background',
      instant: true,
    },

    fieldConfig: {
      overrides: [],
      defaults: {
        noValue: '0',
        color: {
          mode: FieldColorModeId.Fixed,
          fixedColor: statBackgroundColor,
        },
        custom: {
          fillOpacity: 10,
          gradientMode: 'none',
        },
      },
    },
  });
}

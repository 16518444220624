import { promql, Expression, MatchingOperator } from 'tsqtsq';

import { SceneQueryRunner } from '@grafana/scenes';

import { PROMETHEUS_DS } from 'components/common/variables';

import { QueryDetailsStat } from './QueryDetailsStat';

function getData(job: string, digest: string, schema: string, instance: string) {
  return new SceneQueryRunner({
    datasource: PROMETHEUS_DS,
    queries: [
      {
        refId: 'Times called',
        expr: promql.increase({
          expr: new Expression({
            metric: 'mysql_perf_schema_events_statements_total',
            values: { job, instance, digest, schema },
            defaultOperator: MatchingOperator.equal,
          }).toString(),
        }),
        instant: true,
      },
    ],
  });
}

export function timesCalledStat(job: string, digest: string, schema: string, instance: string) {
  return new QueryDetailsStat({
    title: 'Times called',
    $data: getData(job, digest, schema, instance),
  });
}

import { debounce } from 'lodash';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import {
  AdHocFiltersVariable,
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  TextBoxVariable,
} from '@grafana/scenes';
import { Icon, IconButton, Input, useStyles2 } from '@grafana/ui';

import { SCENES_APP_FILTERS_VAR, SCENES_APP_SEARCH_VAR } from 'components/common/variables';
import { getStyles } from 'pages/Overview/search/SearchTextScene.styles';

interface SearchTextState extends SceneObjectState {
  searchTerm: string;
}

function SearchTextRenderer({ model }: SceneComponentProps<SearchTextScene>) {
  const styles = useStyles2((t: GrafanaTheme2) => getStyles(t));

  const { searchTerm } = model.useState();

  return (
    <div className={styles.search}>
      <Input
        name="textSearch"
        value={searchTerm}
        placeholder="Search by query"
        onChange={(e) => model.onTextChanged(e.currentTarget.value)}
        prefix={<Icon name="search" />}
        addonAfter={
          searchTerm && (
            <IconButton name="times" size="lg" onClick={() => model.onTextChanged('')} aria-label="clear search" />
          )
        }
      />
    </div>
  );
}

export class SearchTextScene extends SceneObjectBase<SearchTextState> {
  static Component = SearchTextRenderer;

  public constructor(state: SearchTextState) {
    super(state);
    this.addActivationHandler(this._onActivate);
  }

  private _onActivate = () => {
    const searchVariable = sceneGraph.lookupVariable(SCENES_APP_SEARCH_VAR, this) as TextBoxVariable;
    this.setState({ ...this.state, searchTerm: searchVariable.state.value });
    this._updateAdHocFilters();
  };

  private _updateAdHocFilters() {
    const filters = sceneGraph.lookupVariable(SCENES_APP_FILTERS_VAR, this) as AdHocFiltersVariable;
    const searchTerm = this.state.searchTerm;

    const newFilter = {
      key: 'digest_text',
      operator: '=~',
      value: `(?i:(.*${searchTerm}.*))`,
      hidden: true,
    };

    if (filters) {
      const currentState = filters.state;
      const updatedFilters = currentState.filters.filter((f) => f.key !== 'digest_text');
      if (searchTerm !== '') {
        updatedFilters.push(newFilter);
      }
      filters.setState({ filters: updatedFilters });
    }
  }

  debouncedUpdate = debounce(() => this._updateAdHocFilters(), 1000);

  onTextChanged = (searchValue: string) => {
    const searchVariable = sceneGraph.lookupVariable(SCENES_APP_SEARCH_VAR, this) as TextBoxVariable;

    searchVariable.setState({ value: searchValue });
    this.setState({ ...this.state, searchTerm: searchValue });

    this.debouncedUpdate();
  };
}
